import { useRef, useState, useEffect } from "react";
import imagePaths from "./imagePaths";
import heartIcon from "./icons/heart_icon.svg"
import closeIcon from "./icons/close_icon.svg"
import { useDragControls } from "framer-motion";
import { motion } from "framer-motion";
import { FormControlLabel, Radio } from "@mui/material";
import { ImageCard } from "./Eyetracking";

const minWaitTime = 10;

export default function EyetrackingPicturesOnly() {
    const [imgIndex, setImgIndex] = useState(-1);


    const dataSet = imagePaths.faces;

    // const keys_like = "l"
    // const keys_dislike = "d"
    const ratingKeys = ["", "1", "2", "3", "4"]

    const cardsData = Array.from(Array(10)).map((e, i) => {
        return dataSet.folder + "/" + dataSet.prefix + (i.toString().padStart(2, "0")) + dataSet.suffix;
    });

    // const onDecision = (like) => {
    //     console.log(like ? "like" : "dislike")
    //     const addedResult = imgIndex.toString() + (like ? "L" : "D") + " "
    //     setResultText(resultText + addedResult)
    //     setImgIndex(imgIndex + 1);
    // };
    const onNext = () => {
        setImgIndex(imgIndex + 1);
    }

    useEffect(() => {
        function keyDownHandler(e) {
            onNext();
        }

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [imgIndex]);

    const content = () => {
        return <>
            {imgIndex + "/" + dataSet.maxImageIndex}
            <ImageCard src={cardsData[imgIndex]} onDecision={() => onNext()} />
        </>
    }

    const onCurtainClick = (e) => {
        var newImageIndex = imgIndex;
        if (e.clientX > window.innerWidth * 0.5) {
            newImageIndex++;
            if (newImageIndex > dataSet.maxImageIndex) newImageIndex = dataSet.maxImageIndex + 1;
        } else {
            newImageIndex--;
            if (newImageIndex < 0) newImageIndex = -1;
        }
        setImgIndex(newImageIndex)
        console.log(e)
    }

    return (
        <>
            <div style={styles.curtain} onClick={onCurtainClick}>

                {content()}
            </div>
        </>
    );
}


const cardSize = "85dvh"
// const radioButtonSize = "8dvh";

const styles = {
    likertScale: {
        display: "flex", flexDirection: "row",
        justifyContent: "space-between",
        gap: "1dvw",
        alignItems: "center"
    },
    // radioButton: {
    //     border: "2px solid white",
    //     borderRadius: "100vw",
    //     height: radioButtonSize,
    //     width: radioButtonSize,
    //     transition: "transform 0.2s ease-in-out",
    //     padding: "4px",
    //     boxSizing: "border-box"
    // },
    // radioButtonHover: {
    //     transform: "scale(1.05)"
    // },
    // radioButtonFilling: {
    //     height: "100%", width: "100%",
    //     backgroundColor: "white",
    //     borderRadius: "100vw"
    // },

    curtain: {
        height: "100dvh", width: "100dvw",
        backgroundColor: "#000000",
        zIndex: "100000",
        display: "flex", flexDirection: "column",
        alignItems: "center", justifyContent: "center",
        gap: "1dvh",
        userSelect: "none",
        overflowY: "hidden"
    },
    buttonWrapper: {
        width: cardSize,
        display: "flex", flexDirection: "row",
        alignItems: "center", justifyContent: "center",
        gap: ".5dvw",
        // border:"1px solid white"
        // boxSizing: "border-box",
    },
    imageCard: {
        position: "relative",
        height: cardSize,
        // width: "fit-content",
        // border: "1px solid white",
        // width: "fit-content",
        // height: "fit-content",
        overflow: "clip",
        backgroundColor: "#44444400",
        border: "1px solid white",
        display: "flex", alignItems: "center", justifyContent: "center",
        // objectFit: "contain",
        borderRadius: "1dvh"
        // maxHeight: "90vh"
    },
    image: {
        //  height: "100%",
        position: "relative",
        // height: "100%",
        // width: "100%",

        height: "100%",
        // minHeight: cardSize,
        objectFit: "contain",
        // width: "100%",
        // zIndex: "100001"
    },
    imageLikeFeedback: {
        position: "absolute",
        width: "100%", height: "100%",
        // zIndex: "100002"
    },
    imageLiking: {
        backgroundColor: "rgba(0,255,0,0.25)",

    },
    imageDisliking: {

        backgroundColor: "rgba(255,0,0,0.25)",
    },
    button: {
        height: "8dvh", width: cardSize / 3,
        flexGrow: "1",
        // border: "1px solid #ffffff",
        borderRadius: "1dvh",
        padding: "1dvh",
        transition: "transform 0.2s ease-in-out",
        zIndex: "100000"
    },
    buttonHover: {
        transform: "scale(1.1)",
        zIndex: "100001"
    },
    buttonIcons: {
        height: "100%", width: "100%"
    },
    endText: {
        display: "flex", flexDirection: "column",
        alignItems: "center", gap: "2dvh",
        fontSize: "2.5rem"
    },
    results: {
        color: "#aaaaaa",
        fontSize: "1rem",
        fontFamily: "Consolas"
    }
}

