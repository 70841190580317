export default {
    testing: {
        folder: "eyetracking_images/testing",
        prefix: "testing",
        suffix: ".webp",
        maxImageIndex: 2
    },
    faces: {
        folder: "eyetracking_images/faces",
        prefix: "face",
        suffix: ".webp",
        maxImageIndex: 9
    }
}