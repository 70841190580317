import { useRef, useState, useEffect } from "react";
import imagePaths from "./imagePaths";
import heartIcon from "./icons/heart_icon.svg"
import closeIcon from "./icons/close_icon.svg"
import { useDragControls } from "framer-motion";
import { motion } from "framer-motion";
import { FormControlLabel, Radio } from "@mui/material";

const minWaitTime = 10;

export default function Eyetracking() {
    const [imgIndex, setImgIndex] = useState(-1);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [lastResult, setLastResult] = useState(-1);

    const [resultText, setResultText] = useState("");

    const dataSet = imagePaths.faces;

    // const keys_like = "l"
    // const keys_dislike = "d"
    const ratingKeys = ["", "1", "2", "3", "4"]

    const cardsData = Array.from(Array(10)).map((e, i) => {
        return dataSet.folder + "/" + dataSet.prefix + (i.toString().padStart(2, "0")) + dataSet.suffix;
    });

    // const onDecision = (like) => {
    //     console.log(like ? "like" : "dislike")
    //     const addedResult = imgIndex.toString() + (like ? "L" : "D") + " "
    //     setResultText(resultText + addedResult)
    //     setImgIndex(imgIndex + 1);
    // };

    const onRate = (rating) => {
        // console.log(like ? "like" : "dislike")
        if (seconds < minWaitTime && imgIndex != -1 && rating !== -9) return;
        const addedResult = imgIndex.toString() + ":" + rating + " "
        if (imgIndex >= 0 && imgIndex <= dataSet.maxImageIndex) {
            setResultText(resultText + addedResult)
        }
        setLastResult(rating)
        setImgIndex(imgIndex + 1);
        handleReset();
    };

    const onKeyPress = (e) => {
        console.log(e);
    }

    useEffect(() => {
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                setSeconds((prev) => prev + 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isRunning]);

    const handleReset = () => {
        setSeconds(0);
        setIsRunning(false);
        setTimeout(() => setIsRunning(true), 0); // Restart the timer immediately
    };

    useEffect(() => {
        function keyDownHandler(e) {
            for (let i = 1; i < ratingKeys.length; i++) {
                const boundI = i;
                if (e.key === ratingKeys[boundI]) {
                    onRate(boundI);
                }else if(e.key === "p"){
                    onRate(-9);
                }
            }
            console.log(e)
        }

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [imgIndex, seconds]);

    const content = () => {
        if (imgIndex < 0)
            return <>
                <StartText />
                <LikertScale onRate={onRate} show={true} />
            </>
        if (imgIndex > dataSet.maxImageIndex) return <EndText resultText={resultText} />
        return <>
            <ImageCard src={cardsData[imgIndex]} onDecision={onRate} />
            <LikertScale onRate={onRate} imageIndex={imgIndex} show={seconds >= minWaitTime} />
        </>
    }

    return (
        <>
            <div style={styles.curtain}>
                <div style={{
                    color: "#202020",
                    position: "absolute",
                    left: 0, bottom: 0,
                    fontSize: "1.5rem",
                    margin: "16px"
                }}>{seconds}</div>

                {content()}

                <div style={{
                    color: "#202020",
                    position: "absolute",
                    right: 0, bottom: 0,
                    fontSize: "1.5rem",
                    margin: "16px"
                }}>{lastResult}</div>
            </div>
        </>
    );
}

export function ImageCard({ src, onDecision }) {
    const controls = useDragControls();
    const cardRef = useRef(null);
    const dragLimit = 0;
    const likeThreshold = 200;
    const [startPos, setStartPos] = useState(-1);
    const [liking, setLiking] = useState(null);

    const OnDragEnd = (e) => {
        const diff = e.screenX - startPos;
        // console.log(e)
        if (diff < -likeThreshold) {
            onDecision(false)
        } else if (diff > likeThreshold) {
            onDecision(true)
        }
        setLiking(null);

    };

    const OnDrag = (e) => {
        const diff = e.screenX - startPos;
        // console.log(diff)
        if (diff < -likeThreshold) {
            setLiking(false);
        } else if (diff > likeThreshold) {
            setLiking(true);
        } else {
            setLiking(null);
        }
    }

    const OnDragStart = (e) => {
        setStartPos(e.screenX)
    };

    return (
        <motion.div
            style={styles.imageCard}
            ref={cardRef}
        // drag="x"
        // dragControls={controls}
        // dragConstraints={{ left: -dragLimit, right: dragLimit }}
        // dragSnapToOrigin={true}
        // onDragEnd={(e) => { OnDragEnd(e) }}
        // onDragStart={(e) => { OnDragStart(e) }}
        // onDrag={(e) => { OnDrag(e) }}
        >
            <img src={src} style={styles.image} draggable={false} />
            <div
                style={
                    {
                        ...styles.imageLikeFeedback,
                        ...(liking === null ? {} : liking ? styles.imageLiking : styles.imageDisliking)
                    }}
            />

        </motion.div>
    )
}

function StartText() {
    const containerStyle = {
        display: "flex", flexDirection: "column",
        alignItems: "center",
        gap: ".5rem",
        justifyContent: "center"
    }
    return (
        <>
            <div style={{ ...containerStyle, ...{ height: cardSize }, ...{ fontSize: "1.5rem" } }}>
                <div style={{ fontSize: "3rem" }}>{"Welcome to our eyetracking test."}</div>
                <div style={{ height: "1rem" }} />
                <div>{"We would like you to look at the following photos of 10 people"}</div>
                <div>
                    <span>{"and rate them based on "}</span>
                    <HighlightedText text={"Likeability"} />
                    <span>{" on a scale from 1-4."}</span>
                </div>
                <div style={{ height: "1rem" }} />
                <div>{"1 = Least Likeable, 2 = Less Likeable, 3 = More Likeable, 4 = Most Likeable"}</div>
                <div style={{ height: "1rem" }} />
                <div>{"You can enter your rating with the number keys 1-4 on the keyboard,"}</div>
                <div>{"alternatively you can click with the mouse on the scale below the image."}</div>
                <div style={{ height: "1rem" }} />
                <div>{"The scale shows up after a minimum of 10 seconds,"}</div>
                <div>{"but you can take longer to decide."}</div>
                <div style={{ height: "6rem" }} />
                <div>{"Use the scale below (with keyboard or mouse) to start the test."}</div>

            </div>
        </>
    )
}

function HighlightedText({ text }) {
    return <span style={{ fontWeight: "bold" }}>{text}</span>
}

function EndText({ resultText }) {
    return (
        <div style={styles.endText}>
            <div>{"These were all of the pictures."}</div>
            <div>{"Thank you for participating in our UX eyetracking test."}</div>
            <div style={{ height: "1vh" }} />
            <div style={{color:"#888888", fontSize:"1.5rem", fontStyle: "italic"}}>{"Source of the images: https://thispersondoesnotexist.com/"}</div>
            <div style={{ height: "8vh" }} />
            <div style={styles.results}>{"(" + resultText.trimEnd() + ")"}</div>
        </div>
    )
}

function SwipeButton({ like, color, onClick }) {
    const [hover, setHover] = useState(false);

    return (
        <div
            style={{ ...styles.button, ...(hover ? styles.buttonHover : {}), ...{ backgroundColor: color } }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => { onClick() }}
        >
            <img src={like ? heartIcon : closeIcon} style={styles.buttonIcons} draggable={false} />
        </div >
    )
}

function LikertScale({ onRate, imageIndex, show }) {
    const [rating, setRating] = useState(0);

    const onRadioClick = (radioValue) => {
        setRating(radioValue);
        onRate(radioValue)
    }

    useEffect(() => {
        setRating(0);
    }, [imageIndex]);

    const buttons = [1, 2, 3, 4].map((i, e) => {
        const boundI = i;
        // return <RadioButton number={i} onClick={() => onRadioClick(boundI)} selected={rating == boundI} />
        return (
            <FormControlLabel
                // sx={{ opacity: show ? 1 : 0 }}
                disabled={show === false}
                control={<Radio
                    checked={rating === boundI}
                    onChange={() => onRadioClick(boundI)}
                    value={boundI}
                    sx={{
                        color: "white",
                        '& .MuiSvgIcon-root': {
                            fontSize: 40,
                        },
                        // opacity: show ? 1 : 0
                    }}
                    
                />}
                label={boundI}
                labelPlacement="bottom"
            />
        )
    })

    const textStyle = {
        opacity: show ? 1 : 0
    }

    return (
        <div style={styles.likertScale}>
            <div style={textStyle}>Least Likeable</div>
            {buttons}
            <div style={textStyle}>Most Likeable</div>
        </div>
    )
}

function RadioButton({ number, onClick, selected }) {
    const [hover, setHover] = useState(false);

    return (
        <div
            style={{
                ...styles.radioButton, ...(hover ? styles.radioButtonHover : {})
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {selected && <div style={styles.radioButtonFilling} />}

        </ div >
    )
}

const cardSize = "80vh"
// const radioButtonSize = "8dvh";

const styles = {
    likertScale: {
        display: "flex", flexDirection: "row",
        justifyContent: "space-between",
        gap: "1dvw",
        alignItems: "center"
    },
    // radioButton: {
    //     border: "2px solid white",
    //     borderRadius: "100vw",
    //     height: radioButtonSize,
    //     width: radioButtonSize,
    //     transition: "transform 0.2s ease-in-out",
    //     padding: "4px",
    //     boxSizing: "border-box"
    // },
    // radioButtonHover: {
    //     transform: "scale(1.05)"
    // },
    // radioButtonFilling: {
    //     height: "100%", width: "100%",
    //     backgroundColor: "white",
    //     borderRadius: "100vw"
    // },

    curtain: {
        height: "100vh", width: "100vw",
        backgroundColor: "#000000",
        zIndex: "100000",
        display: "flex", flexDirection: "column",
        alignItems: "center", justifyContent: "center",
        gap: "1dvh",
        userSelect: "none",
        overflowY: "hidden"
    },
    buttonWrapper: {
        width: cardSize,
        display: "flex", flexDirection: "row",
        alignItems: "center", justifyContent: "center",
        gap: ".5dvw",
        // border:"1px solid white"
        // boxSizing: "border-box",
    },
    imageCard: {
        position: "relative",
        // height: cardSize,
        // width: "fit-content",
        // border: "1px solid white",
        // width: "fit-content",
        // height: "fit-content",
        overflow: "clip",
        backgroundColor: "#44444400",
        border: "1px solid white",
        display: "flex", alignItems: "center", justifyContent: "center",
        // objectFit: "contain",
        borderRadius: "1vh"
        // maxHeight: "90vh"
    },
    image: {
        //  height: "100%",
        position: "relative",
        height: cardSize,
        width: cardSize,

        // height: "100%",
        // minHeight: cardSize,
        objectFit: "contain",
        // width: "100%",
        // zIndex: "100001"
    },
    imageLikeFeedback: {
        position: "absolute",
        width: "100%", height: "100%",
        // zIndex: "100002"
    },
    imageLiking: {
        backgroundColor: "rgba(0,255,0,0.25)",

    },
    imageDisliking: {

        backgroundColor: "rgba(255,0,0,0.25)",
    },
    button: {
        height: "8dvh", width: cardSize / 3,
        flexGrow: "1",
        // border: "1px solid #ffffff",
        borderRadius: "1dvh",
        padding: "1dvh",
        transition: "transform 0.2s ease-in-out",
        zIndex: "100000"
    },
    buttonHover: {
        transform: "scale(1.1)",
        zIndex: "100001"
    },
    buttonIcons: {
        height: "100%", width: "100%"
    },
    endText: {
        display: "flex", flexDirection: "column",
        alignItems: "center", gap: "2vh",
        fontSize: "2.5rem"
    },
    results: {
        color: "#aaaaaa",
        fontSize: "1rem",
        fontFamily: "Consolas"
    }
}

