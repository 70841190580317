import "../styles/App.css"
import "../styles/Fonts.css"
import LandingPage from './LandingPage';
import Navbar from './NavBar';
import Footer from './Footer';
import { GlobalContextProvider } from './GlobalContexts';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Projects from "./Projects";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import ProjectPage from "./ProjectPages/ProjectPage";
import { projectsData } from "../data/ProjectsData";
import background from "../assets/website_bg_noise_output.png";
import ImageViewer from "./ImageViewer";
import UpButton from "./UpButton";
import Eyetracking from "../eyetracking/Eyetracking";
import EyetrackingPicturesOnly from "../eyetracking/EyetrackingPictures";

function App() {
	const style = {
		backgroundImage: `url(${background})`,
		backgroundRepeat: "repeat",
		backgroundSize: "8rem",
	}

	return (
		<div className="App" style={style} onScroll={(e) => console.log(e)}>
			<GlobalContextProvider>
				<ImageViewer />
				<Navbar />
				<RoutingResult />
				<Footer />
				<UpButton />
			</GlobalContextProvider>
		</div>
	);
}

function RoutingResult() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="" element={<LandingPage />} />
				{/* <Route path="/projects" element={<Projects />} /> */}
				<Route path="/projects">
					<Route index element={<Projects />} />
					<Route path="/projects/cycorgs" element={<ProjectPage data={projectsData.cycorgs} />} />
					<Route path="/projects/strahlenjaeger" element={<ProjectPage data={projectsData.strahlenjaeger} />} />
					<Route path="/projects/controls_crossover" element={<ProjectPage data={projectsData.controls_crossover} />} />
					<Route path="/projects/unisex" element={<ProjectPage data={projectsData.unisex} />} />
					<Route path="/projects/fruit_platformer" element={<ProjectPage data={projectsData.fruit_platformer} />} />
					<Route path="/projects/cat_universe" element={<ProjectPage data={projectsData.cat_universe} />} />
					<Route path="/projects/cybercourier" element={<ProjectPage data={projectsData.cybercourier} />} />
					<Route path="/projects/website" element={<ProjectPage data={projectsData.website} />} />
					<Route path="/projects/hnuesports" element={<ProjectPage data={projectsData.hnuesports} />} />
					<Route path="/projects/tvin_rooms" element={<ProjectPage data={projectsData.tvin_rooms} />} />
				</Route>
				<Route path="/about_me" element={"about me"} />
				<Route path="/imprint" element={<Imprint />} />
				<Route path="/privacy_policy" element={<PrivacyPolicy />} />
				<Route path="/contact" element={<Imprint />} />

				<Route path="/eyetracking" element={<Eyetracking />} />
				<Route path="/ux" element={<Navigate to={"/eyetracking"} />} />
				<Route path="/e" element={<Navigate to={"/eyetracking"} />} />
				<Route path="/eyetracking_pictures" element={<EyetrackingPicturesOnly />} />
				<Route path="/x" element={<Navigate to={"/eyetracking_pictures"} />} />
				<Route path="/p" element={<Navigate to={"/eyetracking_pictures"} />} />



				<Route path="*" element={<RedirectHome />} />

			</Routes>
		</BrowserRouter>
	)
}

const getProjectData = (url) => {
	let result = null;
	console.log(url)
	Object.entries(projectsData).forEach(key=>{
		if(projectsData[key].url === url){
			result = projectsData[key]
		}
	})
	console.log(result)
	return result;
}

function RedirectHome() {
	return <Navigate to="/" />
}

export default App;
